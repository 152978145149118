import React, { Fragment } from "react";
import { StartNewGame } from "@/modules/home";
import Head from "next/head";
import { useCurrentUser } from "@/contexts/currentUser";
import { QuickLinks, StartGame } from "@/modules/home/components";

export default function Home() {
    const user = useCurrentUser();

    return (
        <Fragment>
            <Head>
                <title>Ziffi Chess</title>

                {/* <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" /> */}
            </Head>
            <main className="mx-auto flex h-full max-h-screen w-full flex-col gap-y-5 bg-primary">
                <div className="flex flex-grow flex-col overflow-hidden">
                    {user?.isUserActivated && user?.isTournamentEnabled ? (
                        <StartGame />
                    ) : (
                        <StartNewGame />
                    )}
                </div>
                <div className="flex flex-col ">
                    <QuickLinks />
                </div>
            </main>
        </Fragment>
    );
}
